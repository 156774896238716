//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ["blok"],
};
