



















import TopBarLink from "./TopBarLink.vue";
export default {
  name: "TheTopBarRight",
  props: ["blok"],
  components: {
    TopBarLink,
  },
};
